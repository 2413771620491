<template>
  <v-col cols="12" class="px-2 mx-0">
    <v-card
      class="mx-1 pb-2 d-flex flex-no-wrap justify-start"
      elevation="2"
      outlined
    >
      <v-row class="pt-10 px-5">
        <v-col cols="12" class="my-0 py-0">
          <span>Seleccione el rango de horas que abarcará el permiso para este día.</span>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <TimeDropdownMenu
            :preSelectedOption="time.startTime"
            :validationMethod="$v.time.startTime"
            :maxTime="maxTime"
            :minTime="minTime"
            :inputMethod="getStartTime"
            :errorMessages="startTimeErrors"
            labelText="Hora de inicio"
          />
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <TimeDropdownMenu
            :preSelectedOption="time.endTime"
            :validationMethod="$v.time.endtime"
            :maxTime="maxTime"
            :minTime="minTime"
            :inputMethod="getEndTime"
            :errorMessages="endTimeErrors"
            labelText="Hora de fin"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import TimeDropdownMenu from "@/components/elements/justifications/TimeDropdownMenu";

import { required } from "vuelidate/lib/validators";

import dayjs from "dayjs";

export default {
  name: "StudentSchedulePanel",
  data: () => ({
    time: {
      startTime: null,
      endTime: null,
    },
  }),
  components: {
    TimeDropdownMenu,
  },
  props: {
    saveMethod: {
      type: Function,
      required: true,
    },
    defaultStartTime: {
      type: String,
      required: true,
    },
    defaultEndTime: {
      type: String,
      required: true,
    },
    minTime: {
      type: String,
      required: true,
    },
    maxTime: {
      type: String,
      required: true,
    },
    datePositionIndex: {
      type: Number,
      required: true
    },
    studentPositionIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    formatTime(value) {
      const timeSplited = value.split(":");
      return dayjs()
        .set("hour", timeSplited[0])
        .set("minutes", timeSplited[1])
        .format("hh:mm a");
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatScheduleTime(value) {
      const timeSplited = value.split("-");
      return (
        this.formatTime(timeSplited[0]) +
        " - " +
        this.formatTime(timeSplited[1])
      );
    },
    /* Getters */
    getStartTime(value) {
      this.time.startTime = value;
      this.$v.time.startTime.$touch();
      this.saveMethod(value, "start_time", this.datePositionIndex, this.studentPositionIndex, this.$v.time.startTime.$invalid);
    },
    getEndTime(value) {
      this.time.endTime = value;
      this.$v.time.endTime.$touch();
      // pass values to main array in parent view
      this.saveMethod(value, "end_time", this.datePositionIndex, this.studentPositionIndex, this.$v.time.endTime.$invalid);
    },
    loadTimes() {
      this.time.startTime = this.defaultStartTime;
      this.time.endTime = this.defaultEndTime;
    },
  },
  computed: {
    endTimeErrors() {
      const errors = [];
      if (!this.$v.time.endTime.$dirty) return errors;
      if (!this.$v.time.endTime.required)
        errors.push("La hora de fin es necesaria.");
      if (!this.$v.time.endTime.checkStartTime)
        errors.push("La hora de fin no puede ser menor a la hora de inicio.");
      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (!this.$v.time.startTime.$dirty) return errors;
      if (!this.$v.time.startTime.required)
        errors.push("La hora de inicio es necesaria.");
      if (!this.$v.time.startTime.checkEndTime)
        errors.push("La hora de inicio no puede ser mayor a la hora de fin")
      return errors;
    },
  },
  beforeMount() {
    this.loadTimes();
  },
  validations: {
    time: {
      startTime: {
        required,
        checkEndTime(val) {
          const endSplited = val.split(":");
          const startSplited = this.time.endTime.split(":");
          const endTime = dayjs()
            .startOf("day")
            .set("hour", endSplited[0])
            .set("minute", endSplited[1]);
          const startTime = dayjs()
            .startOf("day")
            .set("hour", startSplited[0])
            .set("minute", startSplited[1]);
          return endTime.diff(startTime, "minute") < 0;
        }
      },
      endTime: {
        required,
        checkStartTime(val) {
          const endSplited = val.split(":");
          const startSplited = this.time.startTime.split(":");
          const endTime = dayjs()
            .startOf("day")
            .set("hour", endSplited[0])
            .set("minute", endSplited[1]);
          const startTime = dayjs()
            .startOf("day")
            .set("hour", startSplited[0])
            .set("minute", startSplited[1]);
          return endTime.diff(startTime, "minute") > 0;
        },
      },
    },
  },
};
</script>