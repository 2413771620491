<template>
  <div>
    <v-stepper-step
      :complete="step > 4"
      :color="step > 4 ? 'success' : 'primary'"
      step="4"
    >
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Selección de clases a justificar en el permiso
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Seleccione las clases u horarios de los estudiantes elegidos
        anteriormente para justificar su inasistencia en la o las fechas
        estipuladas por el permiso.
      </p>
    </v-stepper-step>
    <v-stepper-content class="mt-0 pt-2" step="4">
      <v-row>
        <v-col cols="12" class="pt-5 my-0 py-0">
          <v-text-field
            label="Motivo del permiso"
            filled
            rounded
            class="my-0 py-0"
            v-model="fourthStep.motive"
            :error-messages="motiveErrors"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-select
            :items="permissionTypes"
            item-text="text"
            item-value="value"
            label="Tipo de permiso"
            filled
            rounded
            v-model="fourthStep.permission_type"
          ></v-select>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-file-input
            label="Archivo de constancia (Opcional)"
            type="file"
            filled
            rounded
            v-model="fourthStep.file"
          >
          </v-file-input>
        </v-col>
        <v-col cols="12" class="d-flex justify-start pb-5 pl-5">
          <v-btn
            color="white"
            class="black--text mt-2 mr-3"
            @click="backThirdStep()"
            :disabled="disableMode"
          >
            Regresar al tercer paso
            <v-icon right>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="white--text mt-2"
            @click="completePermission()"
            :loading="disableMode"
            :disabled="disableMode"
          >
            Completar permiso
            <v-icon right light>mdi-check-bold</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    completeStep: {
      type: Function,
      required: true,
    },
    thirdStep: {
      type: Function,
      required: true,
    },
    disableMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fourthStep: {
      permission_type: "Normal",
      file: null,
      motive: null,
    },
    permissionTypes: [
      {
        text: "Permiso",
        value: "Normal",
      },
      {
        text: "Permiso ITR",
        value: "ITR",
      },
    ],
  }),
  methods: {
    completePermission() {
      this.$v.fourthStep.$touch();
      if (this.$v.fourthStep.$invalid) {
        return this.fireToast({
          icon: "warning",
          title: "Verifica los datos antes de guardar el permiso",
        });
      }
      this.completeStep(this.fourthStep);
    },
    backThirdStep() {
      this.thirdStep();
      this.fourthStep = {
        permission_type: "Normal",
        file: null,
        motive: null,
      };
    },
    resetValues() {
      this.fourthStep = {
        permission_type: "Normal",
        file: null,
        motive: null,
      };
      this.$v.fourthStep.$reset();
    },
  },
  validations: {
    fourthStep: {
      permission_type: {
        required,
      },
      motive: {
        required,
        minLength: minLength(5),
      },
    },
    fourthStep: {
      motive: {
        required,
        minLength: minLength(5),
      },
      permission_type: {
        required,
      },
    },
  },
  computed: {
    motiveErrors() {
      const errors = [];
      if (!this.$v.fourthStep.motive.$dirty) return errors;
      if (!this.$v.fourthStep.motive.required)
        errors.push("Es necesario que ingrese el motivo del permiso.");
      if (!this.$v.fourthStep.motive.minLength)
        errors.push("El motivo debe de contener al menos 5 caracteres.");
      return errors;
    },
  },
};
</script>