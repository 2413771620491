<template>
    <v-expansion-panel class="font-weight-medium button">
      <v-expansion-panel-header
        :class="setColorByState(invalid, 'lighten')"
        class="py-0 pl-0 pr-5 rounded-r-0"
      >
        <v-row class="pa-0 ma-0 d-flex align-center">
          <v-col
            class="pa-1 ma-0 d-flex align-center justify-center"
            :class="setColorByState(invalid, 'darken')"
            lg="1"
            md="2"
            sm="2"
          >
            <v-img
              aspect-ratio="0.90"
              class="ma-1 rounded-lg"
              :src="`${studentInfo.photo}`"
              :alt="`${studentInfo.first_name + ' ' + studentInfo.last_name}`"
              max-height="80"
              max-width="65"
            >
            </v-img>
          </v-col>
          <v-col lg="9" md="9" sm="10" class="pa-0 my-0 mr-0 ml-sm-0 pl-3">
            <div class="d-flex flex-column align-start my-1 py-2">
              <span class="py-1 font-weight-bold"
                >{{
                  studentInfo.first_name +
                  " " +
                  studentInfo.last_name +
                  " - " +
                  studentInfo.code
                }}
              </span>
              <div class="d-flex mt-1">
                <span class="mr-3 font-weight-medium">{{
                  studentInfo.grade + " " + studentInfo.section_group
                }}</span>
                <span class="font-weight-medium">{{
                  studentInfo.speciality
                }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row
          class="px-0 mb- mt-4 d-flex flex-lg-row flex-md-column flex-sm-column"
        >
          <slot></slot>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </template>
  
  <script>
  export default {
    name: "StudentScheduleExpansionPanel",
    props: {
      label: {
        type: String,
      },
      studentInfo: {
        type: Object,
        required: true,
      },
      invalid: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      setColorByState(invalidStatus, level) {
        if (!invalidStatus) {
            return `green ${level}-3`; 
        }

        if (invalidStatus) {
            return `red ${level}-3`;
        }
        return `amber ${level}-3`; 
      },
    }
  };
  </script>