var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.step > 1,"color":_vm.step > 1 ? 'success' : 'primary'}},[_c('p',{staticClass:"font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10"},[_vm._v(" Selección de estudiantes ")]),_c('p',{staticClass:"mt-0 text-body-2 grey--text text--darken-1 mb-0"},[_vm._v(" Selecciones los estudiantes que se les creará el permiso. Puede agregar estudiantes individualmente por nombre o código o seleccionar un grado o grupo técnico en espécifico. ")])]),_c('v-stepper-content',{staticClass:"mt-0 pt-0",attrs:{"step":"1"}},[_c('v-card',{attrs:{"color":"elevation-0"}},[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0 pt-5",attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12 pb-0 mb-0"}},[_c('v-switch',{staticClass:"pl-0 ml-0 my-2 py-0",attrs:{"label":`¿Desea seleccionar estudiantes individualmente? ${
                !_vm.studentsFilterMode ? 'Si' : 'No'
              }`,"disabled":_vm.disabled},on:{"change":function($event){return _vm.changeFilterMode()}},model:{value:(_vm.studentsFilterMode),callback:function ($$v) {_vm.studentsFilterMode=$$v},expression:"studentsFilterMode"}})],1),_c('v-col',{staticClass:"mt-0 pt-0 mb-5",attrs:{"cols":"12"}},[_c('v-scroll-x-transition',{attrs:{"leave-absolute":true,"hide-on-leave":true}},[(!_vm.studentsFilterMode)?_c('v-col',{staticClass:"my-0 py-0 px-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":_vm.studentsObject.isLoading
                      ? 'Cargando estudiantes...'
                      : 'Código del estudiante',"loading":_vm.studentsObject.isLoading,"filled":"","rounded":"","open-on-clear":"","clearable":"","eager":"","hide-details":"","return-object":"","items":_vm.students,"item-text":"code","item-value":"code","filter":_vm.customFilter},on:{"change":function($event){return _vm.addStudent(_vm.studentTemporalElection)}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0 && _vm.studentTemporalElection)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.first_name + " " + item.last_name))])]):_vm._e()]}},{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',[_c('img',{attrs:{"src":`${item.photo}`,"alt":`${
                                item.first_name + ' ' + item.last_name
                              }`}})])],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.code)+" ")]),_vm._v(" - "),_c('span',{staticClass:"text-uppercase font-weight-medium"},[_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":`${
                                item.status == 'Activo'
                                  ? 'success'
                                  : 'red darken-1'
                              }`,"outlined":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.status))])])],1),_c('v-list-item-subtitle',[_c('span',{staticClass:"ml-2"},[_vm._v("Grado "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.grade))])]),_c('span',{staticClass:"ml-2"},[_vm._v(" Sección "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.section_group))])]),_c('span',{staticClass:"ml-2"},[_vm._v(" Especialidad "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.speciality))])])])],1)],1)],1)]}}],null,false,3896808216),model:{value:(_vm.studentTemporalElection),callback:function ($$v) {_vm.studentTemporalElection=$$v},expression:"studentTemporalElection"}})],1):_vm._e()],1),_c('v-scroll-x-transition',{attrs:{"leave-absolute":true,"hide-on-leave":true}},[(_vm.studentsFilterMode)?_c('v-row',{staticClass:"d-flex justify-start flex-sm-column flex-md-row flex-lg-row"},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.gradesObject.data,"item-text":"name","item-value":"id","label":_vm.gradesObject.isLoading ? 'Cargando grados...' : 'Grados',"loading":_vm.gradesObject.isLoading,"filled":"","rounded":"","disabled":_vm.disabled},on:{"change":function($event){return _vm.searchForGrade()}},model:{value:(_vm.gradeElection),callback:function ($$v) {_vm.gradeElection=$$v},expression:"gradeElection"}})],1),_c('v-col',{staticClass:"mt-0 py-0",attrs:{"lg":"6","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.academicSectionSelect.data,"item-text":"grade_section_group_formatted","item-value":"id","label":_vm.academicSectionSelect.isLoading
                        ? 'Cargando secciones...'
                        : 'Sección académica',"loading":_vm.academicSectionSelect.isLoading,"filled":"","rounded":"","disabled":_vm.gradeElection == null || _vm.disabled},on:{"change":function($event){return _vm.loadAcademicStudents()}},model:{value:(_vm.academicElection),callback:function ($$v) {_vm.academicElection=$$v},expression:"academicElection"}})],1),_c('v-col',{staticClass:"mt-0 py-0",attrs:{"lg":"6","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.technicalNameAndGroupsSelect.data,"item-text":"grade_speciality_group_formatted","item-value":"id","label":_vm.technicalNameAndGroupsSelect.isLoading
                        ? 'Cargando grupos técnicos...'
                        : 'Grupo técnico',"loading":_vm.technicalNameAndGroupsSelect.isLoading,"filled":"","rounded":"","disabled":_vm.gradeElection == null || _vm.disabled},on:{"change":function($event){return _vm.loadTecnicalStudents()}},model:{value:(_vm.technicalElection),callback:function ($$v) {_vm.technicalElection=$$v},expression:"technicalElection"}})],1)],1):_vm._e()],1)],1)],1)],1),_c('StudentsTable',{attrs:{"title":"Estudiantes seleccionados","isLoadingStatus":_vm.studentsDataTable.isLoading,"dataArray":_vm.studentsDataTable.data,"headersArray":_vm.studentsDataTable.headers,"deleteOption":_vm.deleteItem}}),_c('v-btn',{staticClass:"white--text mt-2",attrs:{"color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.validateStep()}}},[_vm._v(" Completar primer paso "),_c('v-icon',{attrs:{"right":"","light":""}},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }