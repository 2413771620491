<template>
  <div>
    <v-stepper-step
      :complete="step > 3"
      :color="step > 3 ? 'success' : 'primary'"
      step="3"
    >
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Verificación de horario
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Verifique el rango de horas que abarcará el permiso para cada
        estudiante.
      </p>
    </v-stepper-step>
    <v-stepper-content class="mt-0 pt-2" step="3">
      <v-row>
        <v-col cols="12">
          <v-menu
            v-model="showColorimetry"
            max-width="400"
            :nudge-width="0"
            offset-x
            bottom
            :close-on-click="showColorimetry"
          >
            <template v-slot:activator="{ on, attrs }">
              <p class="py-0 my-0">
                ¿Tienes problemas para identificar el estado de los estudiantes
                con los colores? Haz click
                <a v-bind="attrs" v-on="on" class="font-weight-bold">aquí</a>
                para más información.
              </p>
            </template>
            <v-card>
              <v-card-title>Colorimetría</v-card-title>
              <v-card-text class="flex flex-column">
                <div class="flex flex-column">
                  <v-chip
                    color="green lighten-3"
                    class="text-body-2 font-weight-bold mb-2"
                    >Completado</v-chip
                  >
                  <p class="font-weight-medium mx-2 black--text">
                    Se ha colocado correctamente el rango para el permiso.
                  </p>
                </div>
                <div class="flex flex-column">
                  <v-chip
                    color="red lighten-3"
                    class="text-body-2 font-weight-bold mb-2"
                    >Rango de permiso inválido</v-chip
                  >
                  <p class="font-weight-medium mx-2 black--text">
                    La hora de inicio y la hora de fin es inválida, verifica que
                    la hora de inicio sea mayor que la hora de fin.
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-tabs show-arrows>
            <v-tooltip top v-for="(value, index) in studentData" :key="index">
              <template v-slot:activator="{ on, attrs }">
                <v-tab class="pl-1">
                  <v-chip
                    v-show="value.touched"
                    class="px-2 py-0 mr-2"
                    :color="
                      value.studentsLeftToSelect == 0
                        ? 'green lighten-4'
                        : 'red lighten-4'
                    "
                    v-bind="attrs"
                    v-on="on"
                    >{{ value.studentsLeftToSelect }}</v-chip
                  >
                  {{ value.date }}
                </v-tab>
              </template>
              <span>
                Número de estudiante a los que no se les ha marcado ninguna
                clase/horario
              </span>
            </v-tooltip>
            <v-tab-item
              style="max-height: 450px"
              class="px-2 py-2 overflow-y-auto"
              v-for="(
                studentClassGroupedByDate, studentClassGroupedByDateindex
              ) in studentData"
              :key="studentClassGroupedByDateindex"
            >
              <v-expansion-panels>
                <StudentScheduleExpansionPanel
                  :studentInfo="studentInfo"
                  v-for="(
                    studentInfo, studentInfoindex
                  ) in studentClassGroupedByDate.info"
                  :key="studentInfoindex"
                  :invalid="studentInfo.invalid"
                >
                  <StudentSchedulePanel
                    :studentPositionIndex="studentInfoindex"
                    :datePositionIndex="studentClassGroupedByDateindex"
                    :saveMethod="changeTime"
                    :defaultStartTime="studentInfo.rangeTime.start_time"
                    :defaultEndTime="studentInfo.rangeTime.end_time"
                    :minTime="minClassTime"
                    :maxTime="maxClassTime"
                  >
                  </StudentSchedulePanel>
                </StudentScheduleExpansionPanel>
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs>
        </v-col>
        <v-col cols="12" class="d-flex justify-start pb-5 pl-5">
          <v-btn
            color="white"
            class="black--text mt-2 mr-3"
            @click="executeSecondStep()"
            :disabled="disableMode"
          >
            Regresar al segundo paso
            <v-icon right>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="white--text mt-2"
            @click="executeFourthStep()"
            :disabled="disableMode"
          >
            Completar tercer paso
            <v-icon right light>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
// Components
import StudentScheduleExpansionPanel from "@/components/elements/justifications/studentScheduleSelectionComponents/StudentScheduleExpansionPanel";
import StudentSchedulePanel from "@/components/elements/justifications/studentScheduleSelectionComponents/StudentSchedulePanel";
import dayjs from "dayjs";

export default {
  name: "PermissionThirdStep",
  data: () => ({
    disableMode: false,
    showColorimetry: false,
    studentData: [],
    payload: [],
  }),
  components: {
    StudentScheduleExpansionPanel,
    StudentSchedulePanel,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    secondStepMethod: {
      type: Function,
      required: true,
    },
    fourthStepMethod: {
      type: Function,
      required: true,
    },
    studentInfoArray: {
      type: Array,
      required: true,
    },
    minClassTime: {
      type: String,
    },
    maxClassTime: {
      type: String,
    },
  },
  methods: {
    changeTime(
      newValue,
      valueToChange,
      dateIndex,
      studentIndex,
      validationStatus
    ) {
      // Cambie el valor de las horas
      const student = this.payload[dateIndex].students[studentIndex];
      student[valueToChange] = newValue;
      // Cambia el estado
      this.$set(this.studentData[dateIndex].info, studentIndex, {
        ...this.studentData[dateIndex].info[studentIndex],
        invalid: validationStatus,
      });
    },
    executeSecondStep() {
      this.secondStepMethod();
    },
    executeFourthStep() {
      if (this.thirdStepIsValid()) {
        this.fourthStepMethod(this.payload);
        return;
      }
      this.fireToast({
        icon: "warning",
        title: "Existen estudiantes con una duración del permiso inválida.",
      });
    },
    thirdStepIsValid() {
      const invalidStudents = [];
      /*       this.studentData.forEach((studentArray) => {
        // Element is and array with students grouped by date
        studentArray.info.forEach((studentInfo) => {
          if (studentInfo.invalid) {
            invalidStudents.push(studentInfo);
          }
        });
      }); */
      this.payload.forEach((studentArray) => {
        studentArray.students.forEach((student) => {
          const startTime = dayjs("2000-01-01T" + student.start_time);
          const endTime = dayjs("2000-01-01T" + student.end_time);
          if (endTime.isBefore(startTime)) {
            invalidStudents.push(student);
          }
        });
      });
      return invalidStudents.length === 0;
    },
    resetValues() {
      this.studentData = [];
      this.payload = [];
    },
  },
  watch: {
    studentInfoArray(newValue, oldValue) {
      // Información completa a mostrar
      this.studentData = newValue;
      // Información del payload
      this.payload = newValue.map((completeItem) => {
        const { date, info } = completeItem;
        const dayIntValue = dayjs(date).day();
        if (info) {
          const students = info.map((studentInfo) => {
            return {
              uuid: studentInfo.uuid,
              start_time: studentInfo.rangeTime.start_time,
              end_time: studentInfo.rangeTime.end_time,
            };
          });
          return {
            date: date,
            day_id: dayIntValue,
            students: students,
          };
        }
      });
    },
  },
};
</script>