<template>
  <div>
    <v-stepper-step
      :complete="step > 2"
      :color="step > 2 ? 'success' : 'primary'"
      step="2"
    >
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Selección tiempo a justificar con el permiso
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Seleccione el tiempo que abarcará el permiso para los estudiantes
        elegidos.
      </p>
    </v-stepper-step>
    <v-stepper-content class="mt-0 pt-2" step="2">
      <v-row>
        <v-col cols="12" class="px-5 pt-0">
          <v-card
            color="elevation-0"
            class="col-12 mr-15 pb-4 pt-4 px-5 mb-0 mt-4"
            outlined
          >
            <v-row class="pt-3 mt-0">
              <v-col cols="12">
                <v-select
                  :items="attendaceStatusSelect"
                  item-text="text"
                  item-value="value"
                  label="Tipos de permiso"
                  filled
                  rounded
                  :disabled="disableMode"
                  v-model="secondStep.attendance_status_id"
                />
              </v-col>
              <v-col class="py-0" lg="6" md="6" sm="12">
                <DateDropdownMenu
                  labelText="Fecha de inicio"
                  :allowDates="allowDates"
                  :isDisabled="disableMode"
                  :inputMethod="getStartDate"
                  :errorMessages="startDateErrors"
                  prependInnerIcon="mdi-calendar"
                  :validationMethod="$v.secondStep.startDate"
                  :preSelectedOption="secondStep.startDate"
                  ref="startDate"
                />
              </v-col>
              <v-col class="py-0" lg="6" md="6" sm="12">
                <DateDropdownMenu
                  labelText="Fecha de fin"
                  :allowDates="allowDates"
                  :isDisabled="disableMode"
                  :inputMethod="getEndDate"
                  prependInnerIcon="mdi-calendar"
                  :validationMethod="$v.secondStep.endDate"
                  :errorMessages="endDateErrors"
                  :preSelectedOption="secondStep.endDate"
                  ref="endDate"
                />
              </v-col>
              <v-col cols="12" class="py-0">
                <v-container>
                  <v-checkbox
                    class="mt-0"
                    v-model="setDefaultTime"
                    :label="`¿Desea colocar un rango de horas predeterminado para todos los estudiantes? ${
                      this.setDefaultTime ? 'Si' : 'No'
                    }`"
                  ></v-checkbox>
                </v-container>
                <v-expand-transition>
                  <v-row align="center" v-show="setDefaultTime">
                    <v-col lg="6" md="6" sm="12">
                      <TimeDropdownMenu
                        :preSelectedOption="
                          defaultTime.start_time
                            ? defaultTime.start_time
                            : minClassTime
                        "
                        :validationMethod="$v.secondStep.startTime"
                        :maxTime="maxClassTime"
                        :minTime="minClassTime"
                        :inputMethod="getStartTime"
                        :errorMessages="startTimeErrors"
                        labelText="Hora de inicio"
                        prependInnerIcon="mdi-clock-time-eight-outline"
                        ref="startTime"
                      />
                    </v-col>
                    <v-col lg="6" md="6" sm="12">
                      <TimeDropdownMenu
                        :preSelectedOption="
                          defaultTime.end_time
                            ? defaultTime.end_time
                            : maxClassTime
                        "
                        prependInnerIcon="mdi-clock-time-eight-outline"
                        :validationMethod="$v.secondStep.endTime"
                        :maxTime="maxClassTime"
                        :minTime="minClassTime"
                        :inputMethod="getEndTime"
                        :errorMessages="endTimeErrors"
                        labelText="Hora de fin"
                        ref="endTime"
                      />
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-start pb-5 pl-5">
          <v-btn
            color="white"
            class="black--text mt-2 mr-3"
            @click="firstStep()"
            :disabled="disableMode"
          >
            Regresar al primer paso
            <v-icon right>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="white--text mt-2"
            @click="executeSecondStep()"
            :disabled="disableMode"
          >
            Completar segundo paso
            <v-icon right light>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
// Components
import DateDropdownMenu from "@/components/elements/justifications/DateDropdownMenu";
import TimeDropdownMenu from "@/components/elements/justifications/TimeDropdownMenu";

import dayjs from "dayjs";

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "PermissionSecondStep",
  components: {
    DateDropdownMenu,
    TimeDropdownMenu,
  },
  data: () => ({
    disableMode: false,
    setDefaultTime: false,
    attendaceStatusSelect: [
      {
        text: "Inasistencia",
        value: 2,
      },
      {
        text: "Llegada tarde a la institución",
        value: 5,
      },
    ],
    secondStep: {
      attendance_status_id: 2,
      from_date: null,
      to_date: null,
    },
    defaultTime: {
      start_time: null,
      end_time: null,
    },
  }),
  props: {
    step: {
      type: Number,
      required: true,
    },
    firstStepMethod: {
      type: Function,
      required: true,
    },
    secondStepMethod: {
      type: Function,
      required: true,
    },
    minClassTime: {
      type: String,
      default: null,
    },
    maxClassTime: {
      type: String,
      default: null,
    },
  },
  methods: {
    // Custom methods
    allowDates(val) {
      const dateNumber = dayjs(val).day();
      return (
        dateNumber != 0 &&
        dateNumber != 6 &&
        dayjs(val).startOf("day") >= dayjs().startOf("day")
      );
    },
    // Getter methods
    getStartDate(val) {
      this.secondStep.from_date = val;
      if (this.secondStep.to_date != null) {
        this.$v.secondStep.from_date.$touch();
      }
    },
    getEndDate(val) {
      this.secondStep.to_date = val;
      this.$v.secondStep.to_date.$touch();
    },
    getEndTime(val) {
      this.defaultTime.end_time = val;
      this.$v.defaultTime.end_time.$touch();
    },
    getStartTime(val) {
      this.defaultTime.start_time = val;
      this.$v.defaultTime.start_time.$touch();
    },
    // External method
    executeSecondStep() {
      this.$v.secondStep.$touch();
      this.$v.defaultTime.$touch();
      if (this.$v.secondStep.$invalid || this.$v.defaultTime.$invalid) {
        return this.fireToast({
          icon: "warning",
          title: "Verifique que los datos sean correctos.",
        });
      }
      this.disableMode = true;
      this.secondStepMethod({ ...this.secondStep, ...this.defaultTime });
      this.disableMode = false;
    },
    firstStep() {
      this.firstStepMethod();
      this.secondStep = {
        attendance_status_id: 2,
        from_date: null,
        to_date: null,
        type: null,
      };
      this.defaultTime = {
        start_time: this.minClassTime,
        end_time: this.maxClassTime,
      };
      this.$v.secondStep.startDate.$reset();
      this.$v.secondStep.endDate.$reset();
      this.$v.secondStep.startTime.$reset();
      this.$v.secondStep.endTime.$reset();
      
    },
    // Custom validations
    calcDatesDiff(stringDate, secondStringDate = dayjs().format("YYYY-MM-DD")) {
      return (
        dayjs(stringDate)
          .startOf("day")
          .diff(dayjs(secondStringDate).startOf("day"), "day") >= 0
      );
    },
    // Method to clean dates and time
    resetValues() {
      this.secondStep = {
        attendance_status_id: 2,
        from_date: null,
        to_date: null,
        type: null,
      };
      this.defaultTime = {
        start_time: this.minClassTime,
        end_time: this.maxClassTime,
      };
      this.$v.secondStep.$reset();
      this.$v.defaultTime.$reset();
      this.$refs.endDate.resetStartValidations();
      this.$refs.startDate.resetStartValidations();
      this.$refs.startTime.resetValidation();
      this.$refs.endTime.resetValidation();
    },
  },
  computed: {
    startDateErrors() {
      const errors = [];
      if (!this.$v.secondStep.from_date.$dirty) return errors;
      if (!this.$v.secondStep.from_date.required)
        errors.push("La fecha de inicio es necesaria.");
      if (!this.$v.secondStep.from_date.minDate)
        errors.push(
          "La fecha de inicio debe de ser igual o mayor a la fecha actual."
        );
      if (!this.$v.secondStep.from_date.checkEndDate)
        errors.push("La fecha de inicio debe de ser mayor a la fecha de fin.");
      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (!this.$v.defaultTime.end_time.$dirty) return errors;
      if (!this.$v.defaultTime.end_time.checkRequired)
        errors.push("La hora de fin es necesaria.");
      if (!this.$v.defaultTime.end_time.checkStartTime)
        errors.push(
          "La hora de fin debe de ser igual o mayor a la hora de inicio."
        );
      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (!this.$v.defaultTime.start_time.$dirty) return errors;
      if (!this.$v.defaultTime.start_time.checkRequired)
        errors.push("La hora de inicio es necesaria.");
      return errors;
    },
    endDateErrors() {
      const errors = [];
      if (!this.$v.secondStep.to_date.$dirty) return errors;
      if (!this.$v.secondStep.to_date.minDate)
        errors.push(
          "La fecha de fin debe de ser igual o mayor a la fecha actual."
        );
      if (!this.$v.secondStep.to_date.checkStartDate)
        errors.push(
          "La fecha de fin debe de ser igual o mayor a la fecha de inicio."
        );
      return errors;
    },
  },
  validations: {
    secondStep: {
      from_date: {
        required,
        minDate(val) {
          return this.calcDatesDiff(val);
        },
        checkEndDate(val) {
          return this.calcDatesDiff(this.secondStep.to_date, val);
        },
      },
      to_date: {
        minDate(val) {
          return this.calcDatesDiff(val);
        },
        checkStartDate(val) {
          return this.calcDatesDiff(val, this.secondStep.from_date);
        },
      },
      attendance_status_id: {
        required,
      },
    },
    defaultTime: {
      start_time: {
        checkRequired: requiredIf(function () {
          return this.setDefaultTime;
        }),
      },
      end_time: {
        checkRequired: requiredIf(function () {
          return this.setDefaultTime;
        }),
        checkStartTime(val) {
          if (!this.setDefaultTime) {
            return !this.setDefaultTime;
          }
          const endSplited = val.split(":");
          const startSplited = this.defaultTime.start_time.split(":");
          const endTime = dayjs()
            .startOf("day")
            .set("hour", endSplited[0])
            .set("minute", endSplited[1]);
          const startTime = dayjs()
            .startOf("day")
            .set("hour", startSplited[0])
            .set("minute", startSplited[1]);
          return endTime.diff(startTime, "minute") >= 0 || !this.setDefaultTime;
        },
      },
    },
  },
  watch: {
    maxClassTime(newValue, oldValue) {
      this.defaultTime.end_time = newValue;
    },
    minClassTime(newValue, oldValue) {
      this.defaultTime.start_time = newValue;
    },
  },
};
</script>