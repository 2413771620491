import { PedagogicalDiaryService } from "@/core/services/apis.service";

/**
 * POST request to create justification header
 * @param payload
 * @returns {*}
 */
export const createPermission = (payload) => {
    return PedagogicalDiaryService.post("student-justifications-permissions/store-permission-by-permission-header", payload);
  };


export default {
    createPermission
}