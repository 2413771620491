<template>
  <div>
    <v-stepper-step
      step="1"
      :complete="step > 1"
      :color="step > 1 ? 'success' : 'primary'"
    >
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Selección de estudiantes
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Selecciones los estudiantes que se les creará el permiso. Puede agregar
        estudiantes individualmente por nombre o código o seleccionar un grado o
        grupo técnico en espécifico.
      </p>
    </v-stepper-step>
    <v-stepper-content class="mt-0 pt-0" step="1">
      <v-card color="elevation-0">
        <v-row>
          <v-col cols="12" class="mb-0 pb-0 pt-5">
            <!-- begin::Change filter options mode -->
            <v-col cols="12 pb-0 mb-0">
              <v-switch
                class="pl-0 ml-0 my-2 py-0"
                v-model="studentsFilterMode"
                @change="changeFilterMode()"
                :label="`¿Desea seleccionar estudiantes individualmente? ${
                  !studentsFilterMode ? 'Si' : 'No'
                }`"
                :disabled="disabled"
              ></v-switch>
            </v-col>
            <!-- end::Change filter options mode -->

            <v-col cols="12" class="mt-0 pt-0 mb-5">
              <!-- begin::Filter options (per students) -->
              <v-scroll-x-transition
                :leave-absolute="true"
                :hide-on-leave="true"
              >
                <v-col
                  cols="12"
                  class="my-0 py-0 px-0"
                  v-if="!studentsFilterMode"
                >
                  <v-autocomplete
                    :label="
                      studentsObject.isLoading
                        ? 'Cargando estudiantes...'
                        : 'Código del estudiante'
                    "
                    :loading="studentsObject.isLoading"
                    filled
                    rounded
                    open-on-clear
                    clearable
                    eager
                    hide-details
                    return-object
                    :items="students"
                    item-text="code"
                    item-value="code"
                    v-model="studentTemporalElection"
                    :filter="customFilter"
                    @change="addStudent(studentTemporalElection)"
                  >
                    <!-- begin::selected student-->
                    <!---->
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        color="primary"
                        small
                        v-if="index === 0 && studentTemporalElection"
                      >
                        <span>{{
                          item.first_name + " " + item.last_name
                        }}</span>
                      </v-chip>
                    </template>
                    <!-- end::selected student-->
                    <!-- begin::select students by item option-->
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="1">
                            <v-avatar>
                              <img
                                :src="`${item.photo}`"
                                :alt="`${
                                  item.first_name + ' ' + item.last_name
                                }`"
                              />
                            </v-avatar>
                          </v-col>
                          <v-col cols="11">
                            <v-list-item-title>
                              <span class="font-weight-medium">
                                {{ item.code }}
                              </span>
                              -
                              <span class="text-uppercase font-weight-medium">
                                {{ item.first_name + " " + item.last_name }}
                              </span>
                              <v-chip
                                x-small
                                class="ma-2"
                                :color="`${
                                  item.status == 'Activo'
                                    ? 'success'
                                    : 'red darken-1'
                                }`"
                                outlined
                              >
                                <span class="font-weight-bold">{{
                                  item.status
                                }}</span>
                              </v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="ml-2"
                                >Grado
                                <strong class="ml-1">{{ item.grade }}</strong>
                              </span>
                              <span class="ml-2">
                                Sección
                                <strong class="ml-1">{{
                                  item.section_group
                                }}</strong>
                              </span>
                              <span class="ml-2">
                                Especialidad
                                <strong class="ml-1">{{
                                  item.speciality
                                }}</strong>
                              </span>
                            </v-list-item-subtitle>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </template>
                    <!-- end::select students by item option-->
                  </v-autocomplete>
                </v-col>
              </v-scroll-x-transition>
              <!-- end::Filter options (per students) -->

              <!-- begin::Filter options (per group) -->
              <v-scroll-x-transition
                :leave-absolute="true"
                :hide-on-leave="true"
              >
                <v-row
                  class="d-flex justify-start flex-sm-column flex-md-row flex-lg-row"
                  v-if="studentsFilterMode"
                >
                  <v-col cols="12" class="mb-0 pb-0">
                    <v-select
                      :items="gradesObject.data"
                      item-text="name"
                      item-value="id"
                      :label="
                        gradesObject.isLoading ? 'Cargando grados...' : 'Grados'
                      "
                      :loading="gradesObject.isLoading"
                      filled
                      rounded
                      :disabled="disabled"
                      v-model="gradeElection"
                      @change="searchForGrade()"
                    />
                  </v-col>
                  <!-- begin::Specifc options -->
                  <v-col lg="6" md="6" sm="12" class="mt-0 py-0">
                    <v-select
                      :items="academicSectionSelect.data"
                      item-text="grade_section_group_formatted"
                      item-value="id"
                      :label="
                        academicSectionSelect.isLoading
                          ? 'Cargando secciones...'
                          : 'Sección académica'
                      "
                      :loading="academicSectionSelect.isLoading"
                      filled
                      rounded
                      :disabled="gradeElection == null || disabled"
                      v-model="academicElection"
                      @change="loadAcademicStudents()"
                    />
                  </v-col>
                  <v-col lg="6" md="6" sm="12" class="mt-0 py-0">
                    <v-select
                      :items="technicalNameAndGroupsSelect.data"
                      item-text="grade_speciality_group_formatted"
                      item-value="id"
                      :label="
                        technicalNameAndGroupsSelect.isLoading
                          ? 'Cargando grupos técnicos...'
                          : 'Grupo técnico'
                      "
                      :loading="technicalNameAndGroupsSelect.isLoading"
                      filled
                      rounded
                      :disabled="gradeElection == null || disabled"
                      v-model="technicalElection"
                      @change="loadTecnicalStudents()"
                    />
                  </v-col>
                  <!-- end::Specifc options -->
                </v-row>
              </v-scroll-x-transition>
              <!-- end::Filter options (per group) -->
            </v-col>
          </v-col>
        </v-row>
        <!-- end::Filter options -->
        <!-- begin::Visualizer -->
        <StudentsTable
          title="Estudiantes seleccionados"
          :isLoadingStatus="studentsDataTable.isLoading"
          :dataArray="studentsDataTable.data"
          :headersArray="studentsDataTable.headers"
          :deleteOption="deleteItem"
        />
        <!-- end::Visualizer -->
        <v-btn
          color="primary"
          class="white--text mt-2"
          @click="validateStep()"
          :disabled="disabled"
        >
          Completar primer paso
          <v-icon right light>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card>
    </v-stepper-content>
  </div>
</template>

<script>
import StudentsTable from "@/components/elements/justifications/StudentsTable";
import StudentsSelectionAutoComplete from "@/components/elements/justifications/StudentsSelectionAutoComplete";

import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import studentRepository from "@/repositories/studentRepository";

export default {
  name: "PermissionFirstStep",
  props: {
    step: {
      type: Number,
      required: true,
    },
    colorTheme: {
      type: String,
      default: "blue",
    },
    passStep: {
      type: Function,
      required: true,
    },
    studentsObject: {
      type: Object,
      required: true,
    },
    gradesObject: {
      type: Object,
      required: true,
    },
  },
  components: {
    StudentsTable,
    StudentsSelectionAutoComplete,
  },
  data: () => ({
    disabled: false,
    studentsFilterMode: false,
    // Election for group mode
    gradeElection: null,
    academicElection: null,
    technicalElection: null,
    // Autocomplete value
    studentTemporalElection: null,
    // Data for charge components
    studentsDataTable: {
      data: [],
      headers: [
        {
          text: "Nombres",
          align: "start",
          sortable: true,
          value: "first_name",
        },
        {
          text: "Apellidos",
          align: "start",
          sortable: true,
          value: "last_name",
        },
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: "Especialidad",
          align: "start",
          sortable: true,
          value: "speciality",
        },
        {
          text: "Grado",
          align: "start",
          sortable: true,
          value: "grade",
        },
        {
          text: "Sección",
          align: "start",
          sortable: true,
          value: "section_group",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      isLoading: false,
    },
    students: [],
    academicSectionSelect: {
      data: [],
      isLoading: false,
    },
    gradesSelect: {
      data: [],
      isLoading: false,
    },
    technicalNameAndGroupsSelect: {
      data: [],
      isLoading: false,
    },
  }),
  methods: {
    // Custom filters
    customFilter(item, queryText) {
      const searchText = queryText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const codeMatch = item.code.toString().includes(searchText);
      const firstName = item.first_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      const lastName = item.last_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      return codeMatch || firstName || lastName;
    },
    // Load massive student
    loadAcademicStudents() {
      this.studentsDataTable.data = [];
      this.studentsDataTable.isLoading = true;
      this.disabled = true;
      // Erase any selection in academic section select
      this.technicalElection = null;
      studentRepository
        .getStudentByAcademicGroup(this.academicElection)
        .then(({ data }) => {
          const studentWithMethods = data.map((element) => {
            let student = element;
            student.actions = [
              {
                title: "Remover estudiante de la selección",
                icon: "mdi-delete",
                color: "red",
                method: this.deleteItem,
              },
            ];
            return student;
          });
          this.studentsDataTable.data = studentWithMethods;
        })
        .catch((e) => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los estudiantes",
          });
        })
        .finally(() => {
          this.studentsDataTable.isLoading = false;
          this.disabled = false;
        });
    },
    loadTecnicalStudents() {
      this.studentsDataTable.data = [];
      this.studentsDataTable.isLoading = true;
      this.disabled = true;
      // Erase any selection in technical section select
      this.academicElection = null;
      studentRepository
        .getStudentByTechnicalGroup(this.technicalElection)
        .then(({ data }) => {
          const studentWithMethods = data.map((element) => {
            let student = element;
            student.actions = [
              {
                title: "Remover estudiante de la selección",
                icon: "mdi-delete",
                color: "red",
                method: this.deleteItem,
              },
            ];
            return student;
          });
          this.studentsDataTable.data = studentWithMethods;
        })
        .catch((e) => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los estudiantes",
          });
        })
        .finally(() => {
          this.studentsDataTable.isLoading = false;
          this.disabled = false;
        });
    },
    loadSpecificGradeGroups(GradeID) {
      if (GradeID != null) {
        academicGroupRepository
          .getAcademicGroupsByGrade(GradeID)
          .then(({ data }) => {
            this.academicSectionSelect.data = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar las secciones académicas",
            });
          })
          .finally(() => {
            this.disabled = false;
            this.academicSectionSelect.isLoading = false;
            // Add reset option
            this.academicSectionSelect.data.unshift({
              grade_section_group_formatted: "Selecciona una sección académica",
              id: null,
            });
          });
      }
    },
    loadSpecifcTechnicalGroup(GradeID) {
      if (GradeID != null) {
        technicalGroupRepository
          .getTechnicalGroupsByGrade(GradeID)
          .then(({ data }) => {
            this.technicalNameAndGroupsSelect.data = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar los grupos técnicos",
            });
          })
          .finally(() => {
            this.disabled = false;
            this.technicalNameAndGroupsSelect.isLoading = false;
            this.technicalNameAndGroupsSelect.data.unshift({
              grade_speciality_group_formatted: "Selecciona una especialidad",
              id: null,
            });
          });
      }
    },
    // Load initial data

    changeFilterMode() {
      this.studentTemporalElection = null;
      this.studentsDataTable.data = [];
      this.academicSectionSelect.data = [];
      this.technicalNameAndGroupsSelect.data = [];
      this.gradeElection = null;
    },
    addStudent(student) {
      this.studentTemporalElection = student;
      // Check if students is already in table
      if (this.studentsDataTable.data.includes(student)) {
        this.fireToast({
          icon: "warning",
          title: "El estudiante ya está seleccionado",
        });
      } else if (student != null) {
        this.studentsDataTable.data.push(student);
      }
      // Reset value in autocomplete
      this.$nextTick(() => {
        this.studentTemporalElection = null;
      });
    },
    validateStep() {
      if (this.studentsDataTable.data.length <= 0) {
        return this.fireToast({
          icon: "warning",
          title: "Debes de seleccionar al menos un estudiante para continuar",
        });
      }
      this.passStep(this.studentsDataTable.data);
    },
    deleteItem(itemIndex) {
      this.studentsDataTable.data.splice(itemIndex, 1);
    },
    // Load data for others selects
    searchForGrade() {
      this.disabled = true;
      this.academicSectionSelect.isLoading = true;
      this.technicalNameAndGroupsSelect.isLoading = true;
      // Reset data to don't merge
      this.academicSectionSelect.data = [];
      this.technicalNameAndGroupsSelect.data = [];
      // Search
      this.loadSpecificGradeGroups(this.gradeElection);
      this.loadSpecifcTechnicalGroup(this.gradeElection);
    },
    resetValues() {
      this.studentsFilterMode = false;
      this.studentTemporalElection = null;
      this.studentsDataTable.data = [];
      this.academicSectionSelect.data = [];
      this.technicalNameAndGroupsSelect.data = [];
      this.gradeElection = null;
    },
  },
  watch: {
    studentsObject: {
      handler() {
        this.students = this.studentsObject?.data.map((studentInfo) => {
          let student = studentInfo;
          student.actions = [
            {
              title: "Remover estudiante de la selección",
              icon: "mdi-delete",
              color: "red",
              method: this.deleteItem,
            },
          ];
          return student;
        });
      },
      deep: true,
    },
  },
};
</script>