<template>
  <div>
    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin::Page header and title -->
        <div class="card-title">
          <h3 class="card-label">
            Permisos
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podrá generar permisos para un estudiante, o un grupo de
              estudiantes para futuras clases.</span
            >
          </h3>
        </div>
        <!-- end::Page header and title -->
        <v-row class="mt-5">
          <v-col cols="12" class="px-0 px-sm-3 pl-lg-0 mt-1">
            <v-stepper
              class="mt-n10 pl-0 ml-n6 mr-n5 ml-sm-3"
              vertical
              v-model="step"
              flat
            >
              <!--Begin::First step-->
              <PermissionFirstStep
                ref="firstStepComponent"
                :step="step"
                :gradesObject="gradesSelect"
                :studentsObject="studentsSelect"
                :passStep="passToSecondStep"
              />
              <!--End::First step-->
              <!--Begin::Second step-->
              <PermissionSecondStep
                ref="secondStepComponent"
                :step="step"
                :firstStepMethod="returnFirstStep"
                :secondStepMethod="passToThirdStep"
                :maxClassTime="classTimes.max"
                :minClassTime="classTimes.min"
              />
              <!--End::SecondStep-->
              <!-- Begin:: Third Step-->
              <PermissionThirdStep
                :step="step"
                :secondStepMethod="returnSecondStep"
                :fourthStepMethod="completeThirdStep"
                :studentInfoArray="studentsThirdStepElection"
                :minClassTime="classTimes.min"
                :maxClassTime="classTimes.max"
              />
              <!-- End:: Third Step-->
              <!-- Begin:: Fourth Step-->
              <PermissionFourthStep
                ref="fourthStepComponent"
                :step="step"
                :completeStep="completeForm"
                :thirdStep="returnThirdStep"
                :disableMode="creatingPermission"
              />
              <!-- End:: Fourth Step-->
            </v-stepper>
          </v-col>
        </v-row>
      </template>
    </div>
        <!-- Inicia:: Botón flotante para el historial de permisos -->
        <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          fixed
          fab
          bottom
          right
          color="primary"
          :class="`floatingTranstition ${floatingPosition}`"
          @click="openPermissionsRecord()"
        >
          <v-icon>mdi-clipboard-text-clock</v-icon>
        </v-btn>
      </template>
      <span>Historial de permisos</span>
    </v-tooltip>
    <!-- Finaliza:: Botón flotante para el historial de permisos -->

    <!-- Modal para el historial de permisos -->
    <JustificationModal
      ref="permissionModal"
      titleLabel="Historial de permisos"
      colorTheme="purple"
      :studentClassInformation="permissionInfo.data"
      :isLoading="permissionInfo.isLoading"
      @reloadData="loadPermissions"
      :forCoordinations="true"
      :faultType="2"
    />
  </div>
</template>

<script>
// Step components
import PermissionFirstStep from "@/components/elements/permissions/steps/PermissionFirstStep";
import PermissionSecondStep from "@/components/elements/permissions/steps/PermissionSecondStep";
import PermissionThirdStep from "@/components/elements/permissions/steps/PermissionThirdStep";
import PermissionFourthStep from "@/components/elements/permissions/steps/PermissionFourthStep";

import JustificationModal from "@/components/modals/justification_permission/JustificationModal.vue";

// Repositories
import gradeRepository from "@/repositories/gradeRepository";
import studentRepository from "@/repositories/studentRepository";
import classTimeRepository from "@/repositories/classTimeRepository";
import permissionRepository from "@/repositories/permissionRepository";
import studentJustificationsRepository from "@/repositories/studentJustificationsRepository";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { mapGetters } from "vuex";

import dayjs from "dayjs";
import "dayjs/locale/es";

export default {
  name: "permissions",
  title: "Permisos | GE ITR",
  data: () => ({
    step: 1,
    studentsSelect: {
      data: [],
      isLoading: false,
    },
    gradesSelect: {
      data: [],
      isLoading: false,
    },
    studentsFirstStepElection: [],
    studentsThirdStepElection: [],
    classTimes: {
      min: null,
      max: null,
    },
    // Student with complete info
    payload: {
      motive: null,
      file: null,
      from_date: null,
      to_date: null,
      type: null,
      user_id: null,
      attendance_status_id: null,
      data: [],
      start_time: null,
      end_time: null,
    },
    permissionInfo: {
        data: [],
        isLoading: false,
      },
    creatingPermission: false,
    
      // Floating bottom css
      floatingPosition: "",
  }),
  components: {
    PermissionFirstStep,
    PermissionSecondStep,
    PermissionThirdStep,
    PermissionFourthStep,
    JustificationModal
  },
  methods: {
    // Load initial data
    loadStudents() {
      this.studentsSelect.isLoading = true;
      studentRepository
        .getAllStudents()
        .then(({ data }) => {
          this.studentsSelect.data = data;
        })
        .catch(() =>
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los estudiantes",
          })
        )
        .finally(() => (this.studentsSelect.isLoading = false));
    },
    loadGrades() {
      this.gradesSelect.isLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.gradesSelect.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los grados",
          });
        })
        .finally(() => {
          // Add default option
          this.gradesSelect.data.unshift({
            id: null,
            name: "Selecciona un grado",
          });
          this.gradesSelect.isLoading = false;
        });
    },
    loadMinAndMaxClassTime() {
      classTimeRepository
        .getMinAndMaxClassTime()
        .then(({ data }) => {
          this.classTimes.min = data.data.minClassTime;
          this.classTimes.max = data.data.maxClassTime;
        })
        .catch((e) => {
          this.fireToast({
            icon: "error",
            title: "Ocurrió un error al obtener datos",
          });
        });
    },
    // FirstStep methods
    passToSecondStep(studentArray) {
      this.step = 2;
      this.studentsFirstStepElection = studentArray;
    },
    // Second step methods
    passToThirdStep(data) {
      Object.keys(data).forEach((key) => {
        if (this.payload[key] !== undefined) {
          this.payload[key] = data[key];
        }
      });
      this.groupStudentsByDate();
    },
    groupStudentsByDate() {
      const startDate = this.payload.from_date;
      const endDate = this.payload.to_date;

      let fixedStudents = this.includeMissingFieldsPerStudent();
      // Calculate diffence (in day) fro both dates
      const dateRange = dayjs(endDate).diff(startDate, "day");
      // Convert array of objects to map to easy lookup
      const studentClassByDate = [];
      Array.from({ length: dateRange + 1 }).forEach((_, i) => {
        const currentDate = dayjs(startDate).add(i, "day");
        const dayOfWeek = currentDate.day();
        const dateFormatted = currentDate.format("YYYY-MM-DD");

        // Check if date is not saturday(6) or sunday (0)
        if (dayOfWeek != 0 && dayOfWeek != 6) {
          studentClassByDate.push({
            date: dateFormatted,
            info: fixedStudents,
            touched: false,
          });
        }
      });
      this.studentsThirdStepElection = studentClassByDate;
      this.step = 3;
    },
    includeMissingFieldsPerStudent() {
      const completeStudentInformation = this.studentsFirstStepElection.map(
        (info) => {
          info.rangeTime = {
            start_time: this.payload.start_time
              ? this.payload.start_time
              : this.minClassTime,
            end_time: this.payload.end_time
              ? this.payload.end_time
              : this.maxClassTime,
          };
          info.invalid = false;
          return info;
        }
      );
      return completeStudentInformation;
    },
    // Third step methods
    completeThirdStep(studentArrayConfirmed) {
      this.studentsThirdStepElection = studentArrayConfirmed;
      this.payload.data = JSON.stringify(studentArrayConfirmed);
      this.step = 4;
    },
    // Fourth step methods
    completeForm(value) {
      this.payload.motive = value.motive;
      this.payload.file = value.file;
      this.payload.type = value.permission_type;
      this.payload.user_id = this.currentUserPersonalInfo.id_user;

      const completePayload = this.objectToFormData(this.payload);
      this.creatingPermission = true;
      // Start post
      permissionRepository
        .createPermission(completePayload)
        .then(() => {
          this.fireToast({
            icon: "success",
            title: "Permiso guardado correctamente",
          });
          this.resetForm();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Ocurrió un problema al guardar el permiso",
          });
        })
        .finally(() => {
          this.creatingPermission = false;
        });
    },
    resetForm() {
      this.fourthStep = {
        motive: null,
        file: null,
        permission_type: "Normal",
      };

      this.secondStep = {
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        startTime: "",
        endTime: "",
      };

      this.$refs.secondStepComponent.resetValues();
      this.$refs.firstStepComponent.resetValues();
      this.$refs.fourthStepComponent.resetValues();

      this.step = 1;
    },
    // Back methods
    returnFirstStep() {
      this.step = 1;
      this.$refs.secondStepComponent.resetValues();
    },
    returnSecondStep() {
      this.step = 2;
      // Reset values
      this.payload = {
        motive: null,
        file: null,
        from_date: null,
        to_date: null,
        type: null,
        user_id: null,
        attendance_status_id: null,
        data: [],
        start_time: null,
        end_time: null,
      };
    },
    returnThirdStep() {
      this.step = 3;
      this.$refs.fourthStepComponent.resetValues();
    },
    changePosition() {
      const yPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (yPosition > 265) {
        this.floatingPosition = "moveToUpButton";
        return;
      } 
      this.floatingPosition = "";
    },
    openPermissionsRecord() {
      const currentDate = dayjs().format("YYYY-MM-DD");
      this.$refs.permissionModal.toggleModal();
      this.loadPermissions({ startDate: currentDate, endDate: currentDate });
    },
    loadPermissions(dates) {
      this.permissionInfo.data = [];
      this.permissionInfo.isLoading = true;
      studentJustificationsRepository
      .getJustificationsByRangeDate(dates.startDate, dates.endDate, 1)
      .then(({ data }) => {
        if (data?.message) {
            this.permissionInfo.data = [];
          } else {
            this.permissionInfo.data = data.data;
          }
      })
      .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.permissionInfo.isLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Permisos", route: "permissions" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Permisos");
    this.loadStudents();
    this.loadGrades();
    this.loadMinAndMaxClassTime();
    // Verifica el desplazamiento para mover el botón flotante
    window.addEventListener("scroll", this.changePosition);
  },
  unmounted() {
    window.removeEventListener("scroll", this.changePosition);
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style scoped>

.floatingTranstition {
  transition: all 0.4s ease-out 1 !important;
  transition: 200ms linear 200ms !important;
}

.moveToUpButton {
  bottom: 85px !important;
}
</style>
