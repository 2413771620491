<template>
  <v-autocomplete
    :label="
      isLoadingStatus ? 'Cargando estudiantes...' : 'Código del estudiante'
    "
    :loading="isLoadingStatus"
    outlined
    open-on-clear
    clearable
    eager
    hide-details
    return-object
    :items="dataArray"
    item-text="code"
    item-value="code"
    v-model="election"
    :filter="customFilter"
  >
    <!-- begin::selected student-->
    <!---->
    <template v-slot:selection="{ item, index }">
      <v-chip color="primary" small v-if="index === 0 && election">
        <span>{{ item.first_name + " " + item.last_name }}</span>
      </v-chip>
    </template>
    <!-- end::selected student-->
    <!-- begin::select students by item option-->
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-row>
          <v-col cols="1">
            <v-avatar>
              <img
                :src="`${item.photo}`"
                :alt="`${item.first_name + ' ' + item.last_name}`"
              />
            </v-avatar>
          </v-col>
          <v-col cols="11">
            <v-list-item-title>
              <span class="font-weight-medium">
                {{ item.code }}
              </span>
              -
              <span class="text-uppercase font-weight-medium">
                {{ item.first_name + " " + item.last_name }}
              </span>
              <v-chip
                x-small
                class="ma-2"
                :color="`${
                  item.status == 'Activo' ? 'success' : 'red darken-1'
                }`"
                outlined
              >
                <span class="font-weight-bold">{{ item.status }}</span>
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="ml-2"
                >Grado
                <strong class="ml-1">{{ item.grade }}</strong>
              </span>
              <span class="ml-2">
                Sección
                <strong class="ml-1">{{ item.section_group }}</strong>
              </span>
              <span class="ml-2">
                Especialidad
                <strong class="ml-1">{{ item.speciality }}</strong>
              </span>
            </v-list-item-subtitle>
          </v-col>
        </v-row>
      </v-list-item-content>
    </template>
    <!-- end::select students by item option-->
  </v-autocomplete>
</template>

<script>
export default {
  name: "StudentsSelectionAutoComplete",

  data: () => ({
    election: null,
  }),
  props: {
    dataArray: {
      type: Array,
    },
    isLoadingStatus: {
      type: Boolean,
      default: false,
    },
    getStudentMethod: {
      type: Function,
    },
    preSelectedOption: {
      type: Object,
      required: true
    }
  },
  methods: {
    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      const codeMatch = item.code.toString().includes(searchText);
      const fullNameMatch = item.full_name.toLowerCase().includes(searchText);
      return codeMatch || fullNameMatch;
    },
  },
  watch: {
    preSelectedOption() {
      this.election = this.preSelectedOption;
    }
  }
};
</script>